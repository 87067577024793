<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="margin-top: 50px">
        <el-col :span="17" :offset="3">
          <el-form
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="车牌号码" prop="plate">
                  {{ addForm.plate }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="挂车号码" prop="hangPlate">
                  {{ addForm.hangPlate }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="车辆所有人" prop="carowner">
                  {{ addForm.carowner }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="司机身份证" prop="driverCreticard">
                  {{ addForm.driverCreticard }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="司机姓名" prop="driverName">
                  {{ addForm.driverName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="行车证号码" prop="carLicencecode">
                  {{ addForm.carLicencecode }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="驾驶证号码" prop="driveingLicencecode">
                  {{ addForm.driveingLicencecode }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车辆类型" prop="truckType">
                  {{ addForm.truckType }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="挂车证号码" prop="hangLicencecode">
                  {{ addForm.hangLicencecode }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="应急联系电话" prop="emytelephone">
                  {{ addForm.emytelephone }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="车主手机号码" prop="telephone">
                  {{ addForm.telephone }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="限定载重" prop="truckTypecode">
                  {{ addForm.truckTypecode }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="排放标准" prop="discharge">
                  {{ addForm.discharge }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row style="margin-left: 10px">
        <el-col style="width: 20%">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="身份证正面"
            v-model="addForm.frontOfIdcard"
          />
        </el-col>
        <el-col style="width: 20%">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="驾驶证正面"
            v-model="addForm.frontDriverLicense"
          />
        </el-col>
        <el-col style="width: 20%">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="行驶证正面"
            v-model="addForm.frontDrivingLicense"
        /></el-col>
        <el-col style="width: 20%">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="挂车证正面"
            v-model="addForm.frontTrailerPermit"
        /></el-col>
        <el-col style="width: 20%">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="排放证明"
            v-model="addForm.emissionCertificate"
        /></el-col>
      </el-row>
      <el-row style="margin-top: 50px">
        <el-col :offset="9">
          <template v-if="addForm.useStatus == 0">
            <el-button
              size="mini"
              type="primary"
              class="buttons"
              @click="handlePass"
              >通过</el-button
            >
            <el-button
              size="mini"
              type="danger"
              class="buttons"
              style="background: rgb(255, 89, 38); color: rgb(255, 255, 255)"
              @click="handleReject"
              >驳回</el-button
            >
          </template>
          <el-button size="small" class="buttons" @click="goBack"
            >返回</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import ImgUpload from "@/components/Img-upload.vue";
import { mapState } from "vuex";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        plate: "",
        hangPlate: "",
        carowner: "",
        driverCreticard: "",
        driverName: "",
        driveingLicencecode: "",
        truckType: "",
        hangLicencecode: "",
        emytelephone: "",
        telephone: "",
        truckTypecode: "",
        discharge: "",
        frontOfIdcard: "",
        carLicencecode: "",
        frontDriverLicense: "",
        frontDrivingLicense: "",
        frontTrailerPermit: "",
        emissionCertificate: "",
        useStatus: "1",
      },
      rules: {},
    };
  },
  components: {
    ImgUpload,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    if (this.$route.query.id) {
      this.$http(
        "api1",
        "/api/biz/TrunkInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key];
        }
      });
    }
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handlePass() {
      this.$messageBox
        .confirm("确认" + "通过" + "该货源,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/TrunkInfo/auditAccept",
            "post",
            {
              trunkId: this.$route.query.id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        });
    },
    handleReject() {
      this.$messageBox
        .prompt("请输入驳回原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(async ({ value }) => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/TrunkInfo/auditRefuse",
            "post",
            {
              trunkId: this.$route.query.id,
              refuseReason: value,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        })
        .catch(() => {});
    },
  },
  async getPendingCount() {
    const res = await this.$http("api1", "/api/stat/pendingNum", "get");
    for (let item of this.pendingCount) {
      item.count = res.data[item.key];
    }
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
